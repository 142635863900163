import { SessionResponse } from '../models/SessionResponse';
import AsyncStorage from './AsyncStorage';
import User from '../models/User';
import BaseService, {
    defaultPagingOptions,
    getToken,
    httpHeaders,
    PagingOptions,
    throwIfNotOk,
    UserKey,
    UserTokenKey
} from './BaseService';
import EventEmitter from './EventEmitter';

export const CurrentUserChanged = 'user-changed-current';
export const UserAdded = 'user-added';
export const UserChanged = 'user-changed';
export const UserRemoved = 'user-removed';

class UserService extends BaseService<User> {
    addEventName = UserAdded;
    changedEventName = UserChanged;
    removeEventName = UserRemoved;

    constructor() {
        super(`users`);
    }

    fromJson(data: any): User {
        return User.fromJson(data);
    }

    addAuthenticationStateListener(listener: (user: User | null) => void) {
        EventEmitter.addListener(CurrentUserChanged, listener);
    }

    removeAuthenticationStateListener(listener: (user: User | null) => void) {
        EventEmitter.removeListener(CurrentUserChanged, listener);
    }

    token = getToken;

    tokenSync(): string | undefined {
        try {
            return localStorage.getItem(UserTokenKey) || undefined;
        } catch {
            return undefined;
        }
    }

    login(data: LoginOptions): Promise<SessionResponse> {
        return new Promise<SessionResponse>(async (resolve, reject) => {
            const url = `${this.baseUrl}/login`;
            const headers = await httpHeaders();

            try {
                await this.logout();

                const res = await fetch(url, {
                    method: 'POST',
                    headers: headers,
                    body: JSON.stringify(data)
                });

                await throwIfNotOk(res);

                const json = (await res.json()) as SessionResponse;

                if (json.authenticated) {
                    await AsyncStorage.setItem(UserTokenKey, json.token!);
                    const user = await this.current();
                    EventEmitter.emit(CurrentUserChanged, user);
                    resolve(json);
                } else {
                    reject(new Error(json.message));
                }
            } catch (error) {
                reject(error);
            }
        });
    }

    signup(data: SignupOptions): Promise<SessionResponse> {
        return new Promise<SessionResponse>(async (resolve, reject) => {
            const url = `${this.baseUrl}/signup`;
            const headers = await httpHeaders();

            try {
                await this.logout();

                const res = await fetch(url, {
                    method: 'POST',
                    headers: headers,
                    body: JSON.stringify(data)
                });

                await throwIfNotOk(res);

                const json = (await res.json()) as SessionResponse;

                if (json.authenticated) {
                    await AsyncStorage.setItem(UserTokenKey, json.token!);
                    const user = await this.current();
                    EventEmitter.emit(CurrentUserChanged, user);
                    resolve(json);
                } else {
                    reject(new Error(json.message));
                }
            } catch (error) {
                reject(error);
            }
        });
    }

    logout(): Promise<void> {
        return super.r('post', '/logout', undefined, async () => {
            await AsyncStorage.removeItem(UserTokenKey);
            await AsyncStorage.removeItem(UserKey);
            EventEmitter.emit(CurrentUserChanged, null);
        });
    }

    logoutEverywhere(): Promise<void> {
        return super.r('post', '/logout-everywhere', undefined, async () => {
            await AsyncStorage.removeItem(UserTokenKey);
            await AsyncStorage.removeItem(UserKey);
            EventEmitter.emit(CurrentUserChanged, null);
        });
    }

    changePassword(oldPassword: string, newPassword: string): Promise<User> {
        return this.single(
            'post',
            '/changepassword',
            {
                oldPassword,
                newPassword
            });
    }

    deleteAccount(password: string): Promise<void> {
        return this.r(
            'delete',
            '/me',
            {
                password
            }
        );
    }

    currentSync(): User | undefined {
        try {
            const cachedUserJson = localStorage.getItem(UserKey);
            if (cachedUserJson) {
                return User.fromJson(JSON.parse(cachedUserJson));
            }
        } catch (e) {
            console.log('Failed to load user from cache', e);
        }

        return undefined;
    }

    current(): Promise<User> {
        return new Promise<User>(async (resolve, reject) => {
            try {
                const cachedUserJson = await AsyncStorage.getItem(UserKey);
                if (cachedUserJson) {
                    console.log('Loading user from cache');
                    const json = JSON.parse(cachedUserJson);

                    resolve(User.fromJson(json));
                    return;
                }
            } catch (error) {
                // Fall thru
            }

            try {
                const user = await this.currentFromNetwork();

                await this.cacheUser(user);

                resolve(user);
            } catch (error) {
                reject(error);
            }
        });
    }

    currentFromNetwork(): Promise<User> {
        return super.single('get', '/me');
    }

    updateSelf(data: User): Promise<User> {
        return super.single('put', '/me', data.toJson(), async (json) => {
            const user = User.fromJson(json);
            await this.cacheUser(user);
        });
    }

    deleteSelf(): Promise<void> {
        return super.r('delete', '/me', undefined, async () => {
            await AsyncStorage.removeItem(UserTokenKey);
            await AsyncStorage.removeItem(UserKey);
        });
    }

    list(options: PagingOptions = defaultPagingOptions): Promise<User[]> {
        return super.getList('/', options);
    }

    resetPassword(email: string, clientUrl: string): Promise<void> {
        return super.r('post', '/resetpassword', {email: email, clientUrl: clientUrl});
    }

    createPassword(password: string, hash: string): Promise<void> {
        return super.r('post', '/createpassword', {password, hash});
    }

    // changePassword(data: ChangePasswordOptions): Promise<void> {
    //     return super.r('post', '/changepassword', {
    //         password: data.oldPassword,
    //         newPassword: data.newPassword,
    //         newPasswordConfirm: data.confirmNewPassword
    //     });
    // }
    //
    // search(query: string, options: PagingOptions = defaultPagingOptions): Promise<User[]> {
    //     return this.getList('/search', {
    //         q: query,
    //         ...options
    //     });
    // }

    private cacheUser(user: User): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            try {
                await AsyncStorage.setItem(UserKey, JSON.stringify(user.toJson()));
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    }
}

export default new UserService();

export interface LoginOptions {
    email: string;
    password: string;
}

export interface SignupOptions {
    email: string;
    password: string;
    fullName: string;
}

export interface ChangePasswordOptions {
    oldPassword: string;
    newPassword: string;
    confirmNewPassword: string;
}
