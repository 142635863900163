import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

// tslint:disable-next-line:interface-name
export interface IStateManager {
    get: () => any;
    set: (key: string, value: any) => Promise<void>;
}

export interface StateManagerProps extends RouteComponentProps {
    initialState?: StateManagerState;
    renderApp: (stateManager: IStateManager, initialState?: StateManagerState) => any;
}

export interface StateManagerState {
    [key: string]: any;

    time?: string;
}

const cleanState = (): StateManagerState => ({time: undefined});

class StateManagerClient extends React.Component<StateManagerProps, StateManagerState> implements IStateManager {
    constructor(props: StateManagerProps) {
        super(props);

        this.state = props.initialState || cleanState();
    }

    get = () => this.state;

    set = (key: string, value: any): Promise<void> => {
        return new Promise<void>(async (resolve) => {
            this.setState(
                {[key]: value},
                () => {
                    console.log('setState', this.state);
                    resolve();
                });
        });
    }

    // componentDidUpdate(prevProps: StateManagerProps) {
    //     const oldPath = prevProps.location.pathname;
    //     const newPath = this.props.location.pathname;
    //
    //     if (oldPath !== newPath) {
    //         this.setState(
    //             cleanState(),
    //             () => console.log('Clean state', oldPath, newPath));
    //     }
    // }

    render() {
        const {renderApp} = this.props;
        const state = this.state;

        return <React.Fragment>
            {renderApp(
                {
                    get: this.get,
                    set: this.set
                },
                state)}
        </React.Fragment>;
    }
}

class StateManagerServer implements IStateManager {
    state: StateManagerState = cleanState();

    get = () => {
        return this.state;
    }

    set = (key: string, value: any): Promise<void> => {
        return new Promise<void>(async (resolve) => {
            this.state[key] = value;
            resolve();
        });
    }
}

export default {
    Client: withRouter(StateManagerClient),
    Server: new StateManagerServer()
};
