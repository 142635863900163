import { JsonConvert, JsonObject, JsonProperty, ValueCheckingMode } from 'json2typescript';
import { Assignable, JsonSerializable } from './Serializable';

@JsonObject
export default class Team extends Assignable<Team> implements JsonSerializable {

    @JsonProperty('id', Number, true)
    id?: number = undefined;

    @JsonProperty('organizationId', Number, true)
    organizationId?: number = undefined;

    @JsonProperty('name', String, true)
    name?: string = undefined;

    @JsonProperty('comment', String, true)
    comment?: string = undefined;

    @JsonProperty('active', Boolean, true)
    active?: boolean = true;

    static fromJson(data: any): Team {
        const jsonConvert = new JsonConvert();
        jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;

        return jsonConvert.deserialize(data, Team) as Team;
    }

    toJson(): any {
        const jsonConvert = new JsonConvert();
        jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
        return jsonConvert.serialize(this);
    }
}
