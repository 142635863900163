import * as React from 'react';
import Helmet from 'react-helmet';
import { RouteComponentProps, withRouter } from 'react-router';

interface Props extends RouteComponentProps {
    title?: string;
    description?: string;
}

class DefaultMeta extends React.Component<Props> {
    render() {
        const {title, description, location} = this.props;

        const descriptionContent = description ||
            'Utvärdera den upplevda arbetsmiljön varje dag med ett enkelt knapptryck och ' +
            'få en kontinuerlig överblick av arbetsklimatet.';

        return (
            <Helmet
                title={title || 'Tempen'}
                meta={[
                    {name: 'description', content: descriptionContent},
                    {property: 'og:title', content: title || 'Tempen'},
                    {property: 'og:type', content: 'website'},
                    {property: 'og:url', content: 'https://tempen.net/'},
                    {property: 'og:image', content: 'https://tempen.net/icon512.png'},
                    {property: 'og:description', content: descriptionContent},
                ]}
                link={[
                    {rel: 'canonical', href: `https://tempen.net${location.pathname}`}
                ]}/>
        );
    }
}

export default withRouter(DefaultMeta);
