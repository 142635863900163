import BaseService, { actionHandlerForEvent, PagingOptions } from './BaseService';
import Organization from '../models/Organization';
import AsyncStorage from './AsyncStorage';
import EventEmitter from './EventEmitter';
import OrganizationRole from '../models/OrganizationRole';

export const OrganizationKey = 'organization-json';
export const CurrentOrganizationChanged = 'organization-changed-current';
export const OrganizationAdded = 'organization-added';
export const OrganizationChanged = 'organization-changed';
export const OrganizationRemoved = 'organization-removed';

export interface UsersCount {
    organizationId: number;
    maxUsersForPlan: number;
    usersCount: number;
}

class OrganizationService extends BaseService<Organization> {
    addEventName = OrganizationAdded;
    changedEventName = OrganizationChanged;
    removeEventName = OrganizationRemoved;

    constructor(path: string) {
        super(path);

        // Update current when organization is changed, if it's the current one
        EventEmitter.addListener(OrganizationChanged, async (organization: Organization) => {
            const current = await this.current();
            if (current && current.id === organization.id) {
                await this.setCurrent(organization);
            }
        });

        // Unset current if it's removed
        EventEmitter.addListener(OrganizationRemoved, async (organization: Organization) => {
            const current = await this.current();
            if (current && current.id === organization.id) {
                await this.setCurrent(undefined);
            }
        });
    }

    fromJson(data: any): Organization {
        return Organization.fromJson(data);
    }

    search(options: PagingOptions): Promise<Organization[]> {
        return this.getList('/search', options);
    }

    updatePaymentPlan(organizationId: number, quantity: number, stripeToken: string): Promise<Organization> {
        return this.single(
            'post',
            '/paymentplan',
            {
                organizationId,
                quantity: quantity,
                stripeToken
            },
            actionHandlerForEvent<Organization>(
                this.changedEventName,
                this.fromJson));
    }

    cancelPaymentPlan(organizationId: number): Promise<Organization> {
        return this.single(
            'delete',
            `/paymentplan/${organizationId}`,
            null,
            actionHandlerForEvent<Organization>(
                this.changedEventName,
                this.fromJson));
    }

    addRoles(roles: OrganizationRole[], replace: boolean = false): Promise<OrganizationRole[]> {
        return this.r(
            'post',
            `/roles?mode=${replace ? 'replace' : 'add'}`,
            roles);
    }

    deleteRole(role: OrganizationRole): Promise<void> {
        return this.r('delete', `/roles`, role.toJson());
    }

    countUsers(organization: Organization): Promise<UsersCount> {
        return this.r('get', `/countusers/${organization.id}`);
    }

    current(): Promise<Organization | undefined> {
        return new Promise<Organization | undefined>(async (resolve) => {
            try {
                const json = await AsyncStorage.getItem(OrganizationKey);
                if (json) {
                    resolve(Organization.fromJson(JSON.parse(json)));
                } else {
                    resolve(undefined);
                }
            } catch (error) {
                resolve(undefined);
            }
        });
    }

    setCurrent(organization: Organization | undefined): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            try {
                if (organization) {
                    // When set from a list item, roles are not loaded
                    if (!organization.roles) {
                        organization = await this.getSingle(organization.id!);
                    }

                    const json = JSON.stringify(organization.toJson());
                    await AsyncStorage.setItem(OrganizationKey, json);
                } else {
                    await AsyncStorage.removeItem(OrganizationKey);
                }

                EventEmitter.emit(CurrentOrganizationChanged, organization);

                resolve();
            } catch (error) {
                console.log('Failed to set current organization', error);
                reject(error);
            }
        });
    }
}

export default new OrganizationService('organizations');