import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import Routes from './Routes';
import './styles/HomeStyles.scss';
import DefaultNavigation from './DefaultNavigation';
import { AppStore, GooglePlay } from './StoreBadges';
import DefaultMeta from './DefaultMeta';
import { frontloadConnect } from 'react-frontload';
import { IStateManager, StateManagerState } from './StateManager';

function frontload(props: Props): Promise<void> {
    return new Promise<void>(async (resolve) => {
        // console.log('Starting', props.location.pathname, props.match.params);

        await props.set('time', `id: ${props.match.params.id}`);

        // console.log('Loaded', props.location.pathname, props.get());
        resolve();
    });
}

interface Props extends RouteComponentProps<{ id: string }>, IStateManager, StateManagerState {
}

class Home extends React.Component<Props> {

    onSignup = () => {
        this.props.history.push(Routes.SIGNUP);
    }

    render() {
        return (
            <div className={'home '}>
                <DefaultMeta title={'Tempen - Systematiskt arbetsmiljöarbete'}/>
                <DefaultNavigation/>

                <div className={'home--hero'}>
                    <div className={'default-layout-content'}>
                        <div className={'home--hero--content'}>

                            <h1 className={'ms-font-su'}>Tempen</h1>

                            <div className={'home--hero--text-wrapper'}>
                                <div className={'home--hero--image'}>
                                    <img src={require('./images/iphonex-screenshot.png')}
                                         className={'home--hero--image--image'}
                                         alt={'Bild på app'}/>
                                </div>

                                <div className={'home--hero--text'}>
                                    <h2 className={'ms-font-xxl'}>
                                        Systematiskt arbetsmiljöarbete
                                        <br/>– varje dag
                                    </h2>
                                    <p>Utvärdera den upplevda arbetsmiljön varje dag med ett enkelt knapptryck och
                                        få en kontinuerlig överblick av arbetsklimatet.</p>

                                    <p>
                                        <Link className={'button'}
                                              to={Routes.SIGNUP + '?returnTo=' + Routes.ORGANIZATIONS}>
                                            TESTA GRATIS
                                        </Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <section>
                    <div className={'home--reasons'}>
                        <div>
                            <strong>Som arbetsgivare</strong><br/>
                            får man en kontinuerlig överblick av arbetsklimatet och
                            värdefull statistik av förändringen över tid.
                        </div>

                        <div>
                            <strong>Som anställd</strong><br/>
                            ges man en kort stund av reflektion av dagen, en utcheckning, och
                            kan vila tryggt i att chefen har en inblick i hur man trivs med arbetet.
                        </div>
                    </div>
                </section>

                <div className={'home--why'}>
                    <section>
                        <h2>Varför är det viktigt?</h2>
                        <p>Många går alldeles för länge med en negativ känsla i arbetet, innan de larmar om att något är
                            fel. Det kan vara något i den fysiska miljön som påverkar negativt, det kan vara en
                            snedfördelning av arbetsuppgifter, eller känslan av att inte få använda den egna kompetensen
                            fullt ut. När det väl har gått så långt att man väljer att berätta för arbetsgivaren är det
                            ofta
                            alldeles för sent. Man är redan på väg till en annan arbetsgivare, eller mot
                            sjukskrivning.</p>
                        <p>Genom Tempen kan man få upp ögonen för negativa trender långt tidigare, och agera på dem
                            innan de
                            växer sig för stora och blir svårlösta arbetsmiljöproblem.</p>
                        <p>Genom att analysera statistiken kan man även se ifall sämre dagar är knutet till en viss
                            veckodag, om ett team verkar ha det tyngre än ett annat, eller om det kanske finns
                            slitningar i en grupp.</p>
                        <p>Man kan även få upp ögonen för positiva trender och genom detta sprida goda exempel.</p>
                    </section>
                </div>

                <div className={'home-appstore'}>
                    <section>
                        <h2>Ladda ned gratis app</h2>
                        <p>Som användare kan du använda Tempen-appen för att varje dag berätta hur dagen
                            har varit. Appen frågar varje eftermiddag om din dag.</p>
                        <p>För att använda appen behöver du först
                            ha <Link to={Routes.SIGNUP}>skapat ett konto</Link> och blivit inbjuden
                            till din organisation.</p>

                        <div style={{textAlign: 'center'}}>
                            <AppStore
                                link={'https://itunes.apple.com/sv/app/tempen/id1450419931?l=sv&ls=1&mt=8'}/>
                            &nbsp;&nbsp;
                            <GooglePlay
                                link={'https://play.google.com/store/apps/details?id=se.digitalthjarta.tempenapp'}/>
                        </div>
                    </section>
                </div>

                <div className={'home--pricing'}>
                    <section className={'home--colored-section'}>
                        <h2>Få offert</h2>
                        <p>
                            Att använda Tempen kostar 500 kr / mån eller 5000 kr / år exklusive moms.
                            För organisationer med färre än 50 anställda kostar Tempen 10 kr / användare och månad.
                        </p>

                        <p>
                            <a style={{fontWeight: 'bold'}}
                               href={'mailto:elin.plahn@stravass.se'}>Kontakt oss</a> med
                            information om hur många användare ditt företag har, samt hur du önskar bli fakturerad,
                            så återkommer vi med en offert.
                        </p>

                        <p>Du kan testa gratis så länge du vill för att se hur det fungerar.</p>
                    </section>
                </div>

                <section>
                    <h2>Vad våra användare tycker</h2>

                    <div className={'home--testimonials'}>
                        <div className={'home--testimonials--wrapper'}>
                            <div className={'home--testimonials--item'}>
                                <blockquote>
                                    Jag tycker att det är ett bra sätt att reflektera över dagen och ”lämna den
                                    bakom mig” när jag går hem.
                                </blockquote>
                            </div>

                            <div className={'home--testimonials--item'}>
                                <blockquote>
                                    Att vi använder Tempen på min arbetsplats tycker jag är ett tecken på att man
                                    genuint bryr sig om hur alla mår och trivs.
                                </blockquote>
                            </div>

                            <div className={'home--testimonials--item'}>
                                <blockquote>
                                    Jag tycker Tempen är jättebra, inte minst för en själv då den manar till extra
                                    reflektion och tanke kring hur dagen varit vilket i sin tur leder till tankar om vad
                                    som behöver förändras.
                                </blockquote>
                            </div>

                            <div className={'home--testimonials--item'}>
                                <blockquote>
                                    Smidigt och okomplicerat att utvärdera och man får en känsla av närhet till chef
                                    även om man inte träffas varje dag.
                                </blockquote>
                            </div>

                            <div className={'home--testimonials--item'}>
                                <blockquote>
                                    Tycker det är ett bra verktyg så chefen får en överblick över hur det är i
                                    teamen även när han är på annan plats.
                                </blockquote>
                            </div>

                            <div className={'home--testimonials--item'}>
                                <blockquote>
                                    Tack vare tempen kan jag vara en närvarande chef, även de dagar jag inte
                                    fysiskt är närvarande.
                                </blockquote>
                            </div>

                            <div className={'home--testimonials--item'}>
                                <blockquote>
                                    Jag tryckte rött en dag och redan dagen efter frågade min chef mig om hur jag
                                    mådde och om det fanns något som hon kunde göra just då. Det var inget som
                                    behövde åtgärdas men det var skönt att veta att man blir sedd och att man får
                                    stöttning direkt om det skulle behövas.
                                </blockquote>
                            </div>

                            <div className={'home--testimonials--item'}>
                                <blockquote>
                                    Som närmaste chef, till en ganska stor arbetsgrupp, är Tempen ett bra verktyg att
                                    få en ögonblicksbild av klimatet. För att, utifrån den kunna besluta var jag ska
                                    styra mina resurser och om det är något team jag behöver ligga extra nära just
                                    nu.
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        );
    }
}

export default frontloadConnect(frontload)((props: Props) => {
    return <Home {...props} {...props.get()}/>;
});
