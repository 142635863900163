const checkServer = () => {
    if (process.env.NODE_ENV === 'development') {
        return 'http://localhost:8080/';
    }
    return 'https://api.tempen.net/';
}

const apiPrefix = 'api/v1/';
const serverEndPoint = checkServer();
const apiEndPoint = serverEndPoint + apiPrefix;

export default {
    serverEndPoint,
    apiEndPoint
};

export function buildApiPath(relativePath: string): string {
    let path: string = relativePath;

    if (path.startsWith('/')) {
        path = path.substring(1);
    }

    if (path.startsWith(apiPrefix)) {
        return serverEndPoint + path;
    } else {
        return apiEndPoint + path;
    }
}
