import * as React from 'react';
import { Link } from 'react-router-dom';
import Routes from './Routes';
import withAuthentication, { AuthenticationProps } from './WithAuthentication';

class DefaultNavigation extends React.Component<AuthenticationProps> {
    render() {
        const {authUser} = this.props;

        return (
            <nav>
                {!authUser && <Link to={Routes.LOGIN}>Logga in</Link>}
                {authUser && <Link to={Routes.ACCOUNT}>Administration</Link>}
            </nav>
        );
    }
}

export default withAuthentication(DefaultNavigation);
