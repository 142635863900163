import * as React from 'react';
import DefaultNavigation from './DefaultNavigation';
import { Link } from 'react-router-dom';
import Routes from './Routes';
import './styles/DefaultLayout.scss';

class DefaultHeader extends React.Component {
    render() {
        return (<header>
            <DefaultNavigation/>

            <h1 className={'ms-font-su'}>
                <Link to={Routes.HOME}>
                    Tempen
                </Link>
            </h1>
            <h2 className={'ms-font-xl'}>Systematiskt arbetsmiljöarbete <br/>– varje dag</h2>
        </header>);
    }
}

export default DefaultHeader;
