import { JsonSerializable } from '../models/Serializable';
import AsyncStorage from './AsyncStorage';
import vinkompassenConfig from '../api-config';
import { stringify } from 'qs';
import EventEmitter from './EventEmitter';

export const futch = (url: string,
                      opts: any = {},
                      onProgress?: (progress: number) => void): Promise<XMLHttpRequest> => {
    return new Promise<XMLHttpRequest>((res, rej) => {
        const xhr = new XMLHttpRequest();
        xhr.open(opts.method || 'get', url);

        for (let k in opts.headers || {}) {
            if (opts.headers.hasOwnProperty(k)) {
                xhr.setRequestHeader(k, opts.headers[k]);
            }
        }

        xhr.onload = e => res(e.target as XMLHttpRequest);
        xhr.onerror = rej;

        if (xhr.upload && onProgress) {
            xhr.upload.onprogress = (event: ProgressEvent) => {
                onProgress(event.loaded / event.total);
            };
        }

        xhr.send(opts.body);
    });
};

export function actionHandlerForEvent<T>(eventName: string | undefined, fromJson: (json: any) => T) {
    return async (json: any) => {
        if (eventName) {
            EventEmitter.emit(eventName, fromJson(json));
        }
    };
}

export class BaseUrlService {
    baseUrl?: string;

    constructor(baseUrl: string) {
        if (baseUrl) {
            if (baseUrl.endsWith('/')) {
                baseUrl = baseUrl.substring(0, baseUrl.length - 1);
            }

            if (baseUrl.startsWith('/')) {
                baseUrl = baseUrl.substring(1);
            }

            this.baseUrl = `${vinkompassenConfig.apiEndPoint}${baseUrl}`;
        }

        if (!this.baseUrl) {
            throw new Error('You must set the baseUrl property');
        }
    }

    buildPath(path: string): string {
        if (!path.startsWith('/')) {
            console.log(`Prefixing path ${path}. You might consider always prefixing paths with /`);
            path = '/' + path;
        }

        return this.baseUrl + path;
    }

    r(method: HttpMethod,
      path: string,
      data: any | undefined = undefined,
      action: ((json: any) => Promise<void>) | undefined = undefined): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
            let url = this.buildPath(path);
            const headers = (data instanceof FormData) ? await authHeaders() : await httpHeaders();

            if (method === 'get' && data) {
                const prefix = url.indexOf('?') === -1 ? '?' : '&';
                url += prefix + stringify(data);
            }

            console.info('Request:', url, data);

            try {
                const res = await fetch(url, {
                    method: method,
                    headers: headers,
                    body: (method === 'get' || !data) ?
                        null :
                        (data instanceof FormData) ? data : JSON.stringify(data)
                });

                await throwIfNotOk(res);

                const json = await res.json();

                if (action) {
                    await action(json);
                }

                console.info('Result:', url, json);
                resolve(json);
            } catch (error) {
                reject(error);
            }
        });
    }

    futch(path: string,
          data: any | undefined = undefined,
          onProgress?: (progress: number) => void): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
            let url = this.buildPath(path);
            const headers = (data instanceof FormData) ? await authHeaders() : await httpHeaders();

            console.info(url, data);

            try {
                let response = await futch(
                    url,
                    {
                        method: 'POST',
                        headers: headers,
                        body: data
                    },
                    onProgress
                );

                throwIfNotOkXhr(response);

                let json = JSON.parse(response.responseText);

                resolve(json);
            } catch (error) {
                reject(error);
            }
        });
    }
}

export default class BaseService<T extends JsonSerializable> extends BaseUrlService {

    addedEventName: string | undefined;
    changedEventName: string | undefined;
    removedEventName: string | undefined;

    fromJson(data: any): T {
        throw new Error('fromJson method is not implemented');
    }

    buildPath(path: string): string {
        if (!path.startsWith('/')) {
            console.log(`Prefixing path ${path}. You might consider always prefixing paths with /`);
            path = '/' + path;
        }
        return this.baseUrl + path;
    }

    getList(path: string, data: any | undefined = undefined): Promise<T[]> {
        return new Promise<T[]>(async (resolve, reject) => {
            try {
                const json: any[] = await this.r('get', path, data);
                resolve(json.map(x => this.fromJson(x)));
            } catch (error) {
                reject(error);
            }
        });
    }

    single(method: HttpMethod,
           path: string,
           data: any | undefined = undefined,
           action: ((json: any) => Promise<void>) | undefined = undefined): Promise<T> {
        return new Promise<T>(async (resolve, reject) => {
            try {
                const json = await this.r(method, path, data, action);
                resolve(this.fromJson(json));
            } catch (error) {
                reject(error);
            }
        });
    }

    getSingle(id: number | string): Promise<T> {
        return this.single('get', `/${id}`);
    }

    addSingle(item: T): Promise<T> {
        return this.single(
            'post',
            `/`,
            item.toJson(),
            actionHandlerForEvent(
                this.addedEventName,
                this.fromJson));
    }

    updateSingle(item: T): Promise<T> {
        return this.single(
            'put',
            `/`,
            item.toJson(),
            actionHandlerForEvent(
                this.changedEventName,
                this.fromJson));
    }

    removeSingle(item: T): Promise<void> {
        return this.r(
            'delete',
            `/${item.id}`,
            undefined,
            actionHandlerForEvent(
                this.removedEventName,
                this.fromJson));
    }
}

export type HttpMethod = 'get' | 'post' | 'put' | 'delete';

export const UserTokenKey = 'user-token';
export const UserKey = 'user-json';

export async function getToken(): Promise<string> {
    return new Promise<string>(async (resolve, reject) => {
        try {
            resolve(await AsyncStorage.getItem(UserTokenKey));
        } catch (error) {
            reject(error);
        }
    });
}

export async function authHeaders(): Promise<any> {
    return new Promise<any>(async (resolve) => {
        try {
            resolve({
                'Authorization': await getToken()
            });
        } catch (error) {
            resolve({});
        }
    });
}

export async function httpHeaders(cType: string = 'application/json'): Promise<any> {
    const contentType = {'Content-Type': cType};

    return new Promise<any>(async (resolve, reject) => {
        try {
            resolve({
                ...contentType,
                ...await authHeaders()
            });
        } catch (error) {
            resolve(contentType);
        }
    });
}

export interface PagingOptions {
    start: number;
    limit: number;
}

export const defaultPagingOptions: PagingOptions = {
    start: 0,
    limit: 100
};

export async function throwIfNotOk(res: Response) {
    if (!res.ok) {
        console.log(res);

        if (res.status === 401) {
            throw new Error('Unauthorized');
        }

        const contentType = res.headers.get('content-type');
        if (contentType && contentType.startsWith('application/json')) {
            const json = await res.json();
            throw new Error(json.error);
        } else {
            console.log(res.status, await res.text());
            throw new Error('Server error');
        }
    }
}

export function throwIfNotOkXhr(res: XMLHttpRequest) {
    if (res.status < 200 && res.status >= 400) {
        console.log(res);

        if (res.status === 401) {
            throw new Error('Unauthorized');
        }

        const contentType = res.getResponseHeader('content-type');
        if (contentType && contentType.startsWith('application/json')) {
            const json = JSON.parse(res.responseText);
            throw new Error(json.error);
        } else {
            console.log(res.status, res.responseText);
            throw new Error('Server error');
        }
    }
}
