import * as React from 'react';
import { Link } from 'react-router-dom';
import 'office-ui-fabric-react/dist/css/fabric.css';
import './styles/Navigation.css';
import User from './models/User';
import Organization from './models/Organization';
import EventEmitter from './services/EventEmitter';
import organizationService, { CurrentOrganizationChanged, OrganizationChanged } from './services/OrganizationService';
import resultOrNull from './ResultOrNull';
import userService, { CurrentUserChanged } from './services/UserService';
import Routes from './Routes';
import { AdministratorRole } from './models/UserRole';

interface Props {
}

interface State {
    loading: boolean;
    user?: User;
    organization?: Organization;
}

class Navigation extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            loading: true
        };
    }

    async componentDidMount() {
        try {
            EventEmitter.addListener(CurrentOrganizationChanged, this.onOrganizationChanged);
            EventEmitter.addListener(OrganizationChanged, this.onOrganizationChanged);
            EventEmitter.addListener(CurrentUserChanged, this.onUserChanged);

            const organization = await organizationService.current();
            const user = await resultOrNull(() => userService.current());

            this.setState({
                loading: false,
                organization,
                user: user || undefined,
            });
        } catch (error) {
            console.log(error);
        }
    }

    componentWillUnmount(): void {
        EventEmitter.removeListener(CurrentOrganizationChanged, this.onOrganizationChanged);
        EventEmitter.removeListener(OrganizationChanged, this.onOrganizationChanged);
        EventEmitter.removeListener(CurrentUserChanged, this.onUserChanged);
    }

    onOrganizationChanged = (organization: Organization) => {
        this.setState({
            organization
        });
    }

    onUserChanged = (user: User) => {
        this.setState({
            user
        });
    }

    render() {
        const {user, organization, loading} = this.state;

        if (loading) {
            return <nav className={'ms-Grid'}/>;
        }

        if (!user) {
            return (<nav className={'ms-Grid'}>
                <Link to={Routes.LOGIN}>Logga in</Link>
                <Link to={Routes.SIGNUP}>Skapa konto</Link>
            </nav>);
        }

        if (!organization) {
            return (<nav className={'ms-Grid'}>
                <Link to={Routes.ACCOUNT}>Ditt konto</Link>
                <Link to={Routes.ORGANIZATIONS}>Välj organisation</Link>
                <Link to={Routes.ORGANIZATION_CREATE}>Skapa organisation</Link>
            </nav>);
        }

        const links: JSX.Element[] = [];
        links.push(<Link key={Routes.ACCOUNT} to={Routes.ACCOUNT}>Ditt konto</Link>);

        if (organization.isReporter(user)) {
            links.push(<Link key={Routes.REPORTS} to={Routes.REPORTS}>Rapporter</Link>);
        }

        if (organization.isOwner(user)) {
            links.push(<Link key={Routes.ORGANIZATION} to={Routes.ORGANIZATION}>Organisation</Link>);
        }

        if ((user.roles || []).indexOf(AdministratorRole) !== -1) {
            links.push(<Link key={Routes.ADMIN_MENU} to={Routes.ADMIN_MENU}>Administration</Link>);
        }

        return (
            <nav className={'ms-Grid'}>
                {links.map(x => x)}
            </nav>);
    }
}

export default Navigation;
