import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import './styles/HomeStyles.scss';

class Contact extends React.Component<RouteComponentProps> {
    render() {
        return (
            <div className={'home '}>

                <div>
                    <section>
                        <h2>Kontakta oss</h2>
                        <p>
                            Tempen är ett samarbete mellan Digitalt Hjärta AB och Stråvass AB. Digitalt Hjärta AB
                            står för tekniken, medan Stråvass AB sköter ekonomi och kan hjälpa dig med pedagogiska
                            frågor.
                        </p>

                        <p>För frågar av teknisk karaktär kan du
                            kontakta <a href="mailto:info@digitalthjarta.se">info@digitalthjarta.se</a>.</p>

                        <p>Har du frågor som berör fakturor eller betalningar kontaktar
                            du <a href="mailto:elin.plahn@stravass.se">elin.plahn@stravass.se</a>.</p>
                    </section>
                </div>
            </div>
        );
    }
}

export default Contact;
