import * as React from 'react';

interface Props {
    link: string;
}

export class AppStore extends React.PureComponent<Props> {
    render() {
        return (
            <a href={this.props.link}
               title={'Hämta i AppStore'}
               style={{
                   display: 'inline-block',
                   overflow: 'hidden',
                   background: 'url(https://linkmaker.itunes.apple.com/sv-se/badge-lrg.svg' +
                       '?releaseDate=2016-05-27&kind=iossoftware&bubble=ios_apps) no-repeat',
                   width: 135, height: 40
               }}/>);
    }
}

export class GooglePlay extends React.PureComponent<Props> {
    render() {
        return (
            <a href={this.props.link}
               title={'Ladda ned på Google Play'}
               style={{
                   display: 'inline-block',
                   overflow: 'hidden',
                   background: 'url(https://play.google.com/intl/en_us/badges/images/generic/' +
                       'sv_badge_web_generic.png) no-repeat center center',
                   width: 135,
                   height: 40,
                   backgroundSize: '155px 60px'
               }}
            />);
    }
}
