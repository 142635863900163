import BaseService from './BaseService';
import Organization from '../models/Organization';
import Team from '../models/Team';
import AsyncStorage from './AsyncStorage';
import EventEmitter from './EventEmitter';

export const TeamAdded = 'team-added';
export const TeamChanged = 'team-changed';
export const TeamRemoved = 'team-removed';
export const TeamNotificationAdded = 'team-notification-added';
export const TeamNotificationRemoved = 'team-notification-removed';
export const CurrentTeamChanged = 'team-current-changed';
export const TeamKey = 'team-current';

class TeamService extends BaseService<Team> {
    addEventName = TeamAdded;
    changedEventName = TeamChanged;
    removeEventName = TeamRemoved;

    fromJson(data: any): Team {
        return Team.fromJson(data);
    }

    getTeams(organization: Organization): Promise<Team[]> {
        return this.getList(`/organization/${organization.id!}`);
    }

    getNotificationTeams(): Promise<Team[]> {
        return this.getList(`/notifications/`);
    }

    addNotification(team: Team): Promise<void> {
        return this.r(
            'post',
            `/notifications/${team.id}`,
            null,
            async () => {
                EventEmitter.emit(TeamNotificationAdded);
            });
    }

    removeNotification(team: Team): Promise<void> {
        return this.r(
            'delete',
            `/notifications/${team.id}`,
            null,
            async () => {
                EventEmitter.emit(TeamNotificationRemoved);
            });
    }

    current(): Promise<Team | undefined> {
        return new Promise<Team | undefined>(async (resolve) => {
            try {
                const json = await AsyncStorage.getItem(TeamKey);
                if (json) {
                    resolve(Team.fromJson(JSON.parse(json)));
                } else {
                    resolve(undefined);
                }
            } catch (error) {
                resolve(undefined);
            }
        });
    }

    setCurrent(team: Team | undefined): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            try {
                if (team) {
                    const json = JSON.stringify(team.toJson());
                    await AsyncStorage.setItem(TeamKey, json);
                } else {
                    await AsyncStorage.removeItem(TeamKey);
                }

                EventEmitter.emit(CurrentTeamChanged, team);

                resolve();
            } catch (error) {
                console.log('Failed to set current team', error);
                reject(error);
            }
        });
    }

}

export default new TeamService('teams');