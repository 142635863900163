import * as React from 'react';
import { HeaderProps } from './Header';
import Organization from './models/Organization';
import organizationService, { CurrentOrganizationChanged, OrganizationChanged } from './services/OrganizationService';
import userService, { CurrentUserChanged, UserChanged } from './services/UserService';
import teamService from './services/TeamService';
import EventEmitter from './services/EventEmitter';
import User from './models/User';
import resultOrNull from './ResultOrNull';
import { Link, withRouter } from 'react-router-dom';
import Routes from './Routes';

interface State {
    user?: User;
    organization?: Organization;
    hasMultipleOrganizations: boolean;
}

class OrganizationHeader extends React.Component<HeaderProps, State> {
    constructor(props: HeaderProps) {
        super(props);

        this.state = {
            hasMultipleOrganizations: false
        };
    }

    async componentDidMount() {
        try {
            EventEmitter.addListener(CurrentOrganizationChanged, this.onOrganizationChanged);
            EventEmitter.addListener(OrganizationChanged, this.onOrganizationChanged);
            EventEmitter.addListener(CurrentUserChanged, this.onUserChanged);
            EventEmitter.addListener(UserChanged, this.onUserChanged);

            const organization = await organizationService.current();
            const user = await resultOrNull(() => userService.current());
            const orgs = await organizationService.getList('/');

            this.setState({
                organization,
                user: user || undefined,
                hasMultipleOrganizations: orgs.length > 1
            });
        } catch (error) {
            console.log(error);
        }
    }

    componentWillUnmount(): void {
        EventEmitter.removeListener(CurrentOrganizationChanged, this.onOrganizationChanged);
        EventEmitter.removeListener(OrganizationChanged, this.onOrganizationChanged);
        EventEmitter.removeListener(CurrentUserChanged, this.onUserChanged);
        EventEmitter.removeListener(UserChanged, this.onUserChanged);
    }

    onOrganizationChanged = (organization: Organization) => {
        this.setState({
            organization
        });
    }

    onUserChanged = (user: User) => {
        this.setState({
            user
        });
    }

    onLogout = async () => {
        try {
            await userService.logout();
            await organizationService.setCurrent(undefined);
            await teamService.setCurrent(undefined);
            this.props.history.push(Routes.LOGIN);
        } catch (error) {
            alert(error.message);
        }
    }

    render() {
        const {user, organization, hasMultipleOrganizations} = this.state;
        return (
            <div>
                {user && <span>{user.fullName}</span>}
                {' '}
                {user && <a onClick={() => this.onLogout()} style={{cursor: 'pointer'}}>
                    (logga ut)
                </a>}
                <br/>
                {organization && <span>{organization.name}</span>}
                {' '}
                {hasMultipleOrganizations && <Link to={Routes.ORGANIZATIONS}>(byt organisation)</Link>}
            </div>
        );
    }
}

export default withRouter(OrganizationHeader);
