export default class AsyncStorage {
    static getItem(key: string, defaultValue?: string): Promise<string> {
        return new Promise<string>(async (resolve, reject) => {
            try {
                const data = localStorage.getItem(key);
                if (data) {
                    resolve(data);
                } else {
                    if (defaultValue) {
                        resolve(defaultValue);
                    } else {
                        reject(new Error());
                    }
                }
            } catch (error) {
                reject(error);
            }
        });
    }

    static setItem(key: string, value: string): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            try {
                localStorage.setItem(key, value);
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    }

    static removeItem(key: string): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            try {
                localStorage.removeItem(key);
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    }
}