import * as React from 'react';

class Loading extends React.PureComponent {
    render() {
        return (<div style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'rgba(0,0,0,0.5)',
            padding: 20,
            borderRadius: 10,
            color: '#fff'
        }}>
            Laddar sidan…
        </div>);
    }
}

export default Loading;
