import { JsonConvert, JsonObject, JsonProperty, ValueCheckingMode } from 'json2typescript';
import { Ownable } from './Ownable';
import { Assignable, JsonSerializable } from './Serializable';
import User from './User';
import DateConverter from './DateConverter';
import OrganizationRole, {
    OrganizationOwnerRole,
    OrganizationReportingRole,
    OrganizationRoles,
    OrganizationUserRole
} from './OrganizationRole';

// @JsonObject
// export class OrganizationRolesProperty {
//     @JsonProperty('owners', [User], true)
//     owners?: User[] = [];
//
//     @JsonProperty('reporters', [User], true)
//     reporters?: User[] = [];
//
//     @JsonProperty('members', [User], true)
//     members?: User[] = [];
// }

@JsonObject
export default class Organization extends Assignable<Organization> implements JsonSerializable, Ownable {

    @JsonProperty('id', Number, true)
    id?: number = undefined;

    @JsonProperty('ownerId', Number, true)
    ownerId?: number = undefined;

    @JsonProperty('name', String, true)
    name?: string = undefined;

    @JsonProperty('paymentPlan', Number, true)
    paymentPlan?: number = undefined;

    @JsonProperty('billingEmail', String, true)
    billingEmail?: string = undefined;

    @JsonProperty('billingName', String, true)
    billingName?: string = undefined;

    @JsonProperty('billingAddress1', String, true)
    billingAddress1?: string = undefined;

    @JsonProperty('billingAddress2', String, true)
    billingAddress2?: string = undefined;

    @JsonProperty('billingZipCode', String, true)
    billingZipCode?: string = undefined;

    @JsonProperty('billingCity', String, true)
    billingCity?: string = undefined;

    @JsonProperty('billingCountry', String, true)
    billingCountry?: string = undefined;

    @JsonProperty('billingReference', String, true)
    billingReference?: string = undefined;

    @JsonProperty('vatNumber', String, true)
    vatNumber?: string = undefined;

    @JsonProperty('created', DateConverter, true)
    created?: Date = undefined;

    @JsonProperty('modified', DateConverter, true)
    modified?: Date = undefined;

    @JsonProperty('lastPayment', DateConverter, true)
    lastPayment?: Date = undefined;

    @JsonProperty('paymentExpires', DateConverter, true)
    paymentExpires?: Date = undefined;

    @JsonProperty('maxNumberOfUsers', Number, true)
    maxNumberOfUsers?: number = undefined;

    @JsonProperty('renewAutomatically', Boolean)
    renewAutomatically?: boolean = false;

    @JsonProperty('roles', [OrganizationRole], true)
    roles?: OrganizationRole[] = undefined;

    @JsonProperty('users', [User], true)
    users?: User[] = undefined;

    static fromJson(data: any): Organization {
        const jsonConvert = new JsonConvert();
        jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;

        return jsonConvert.deserialize(data, Organization) as Organization;
    }

    toJson(): any {
        const jsonConvert = new JsonConvert();
        jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
        return jsonConvert.serialize(this);
    }

    isOwner(user: User): boolean {
        return this.isInRole(user, OrganizationOwnerRole);
    }

    isReporter(user: User): boolean {
        return this.isInRole(user, OrganizationReportingRole);
    }

    isMember(user: User): boolean {
        return this.isInRole(user, OrganizationUserRole);
    }

    isInRole(user: User, role: OrganizationRoles): boolean {
        if (!this.roles) {
            return false;
        }

        return !!this.roles.find(x => x.userId === user.id && x.role === role);
    }

    owners(): User[] {
        return this.usersInRole(OrganizationOwnerRole);
    }

    reporters(): User[] {
        return this.usersInRole(OrganizationReportingRole);
    }

    members(): User[] {
        return this.usersInRole(OrganizationUserRole);
    }

    usersInRole(role: OrganizationRoles): User[] {
        if (!this.roles || !this.users) {
            return [];
        }

        const userIds = this.roles.filter(x => x.role === role).map(x => x.userId);
        return this.users.filter(x => userIds.find(id => id === x.id));
    }
}

@JsonObject
export class OrganizationsViewModel {
    @JsonProperty('users', [User], true)
    users?: User[] = [];

    @JsonProperty('organizations', [Organization], true)
    organizations?: Organization[] = [];

    static fromJson(data: any): OrganizationsViewModel {
        const jsonConvert = new JsonConvert();
        jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;

        return jsonConvert.deserialize(data, OrganizationsViewModel) as OrganizationsViewModel;
    }
}