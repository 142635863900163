export interface JsonSerializable {
    id?: number | string;

    toJson(): any;
}

export class Assignable<T extends JsonSerializable> {
    assign(input: T): T {
        return Object.assign(this, input);
    }

    project(options: ProjectionOptions): any {
        let obj: any = {};

        if (options.mode === 'include' || options.mode === '+') {
            options.keys.forEach(x => {
                // @ts-ignore
                obj[x] = this[x];
            });
        } else {
            Object.assign(obj, this);
            options.keys.forEach(x => {
                delete obj[x];
            });
        }

        if (options.convert) {
            Object.keys(options.convert).forEach(key => {
                if (obj.hasOwnProperty(key) && options.convert && options.convert[key]) {
                    obj[key] = options.convert[key](obj[key]);
                }
            });
        }

        return obj;
    }
}

export interface ProjectionOptions {
    keys: string[];
    mode: 'exclude' | 'include' | '-' | '+';
    convert?: { [key: string]: ((value: any) => any) };
}

export function toUnixTimestamp(date: Date): number | undefined {
    return date && date.getTime() / 1000 || undefined;
}