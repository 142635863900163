import * as React from 'react';
import { LayoutProps } from './App';
import { Route } from 'react-router';
import './styles/DefaultLayout.scss';
import DefaultHeader from './DefaultHeader';
import DefaultMeta from './DefaultMeta';

const DefaultLayout = ({component: Component, ...rest}: LayoutProps<any>) => (
    <Route {...rest} render={matchProps => (
        <div className="default-layout">
            <DefaultMeta/>
            <DefaultHeader/>

            <Component {...matchProps} />
        </div>
    )}/>
);

export default DefaultLayout;
