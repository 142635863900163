import * as React from 'react';
import AuthUserContext from './AuthUserContext';
import users from './services/UserService';
import User from './models/User';

export interface AuthenticationState {
    authUser: User | null;
}

export interface AuthenticationProps extends AuthenticationState {

}

const withAuthentication = (Component: any) =>
    class WithAuthentication extends React.Component<any, AuthenticationState> {
        constructor(props: any) {
            super(props);

            this.state = {
                authUser: null
            };
        }

        async componentDidMount() {
            try {
                const appUser = await users.current();
                this.setState({
                    authUser: appUser
                });

                users.addAuthenticationStateListener(this.authChangedListener);
            } catch (error) {
                this.setState({authUser: null});
            }
        }

        componentWillUnmount() {
            users.removeAuthenticationStateListener(this.authChangedListener);
        }

        authChangedListener = (user: User | null) => {
            console.log('Got user changed event', user);
            this.setState({
                authUser: user
            });
        };

        render() {
            const {authUser} = this.state;

            return (
                <AuthUserContext.Provider value={authUser}>
                    <Component {...this.props} {...this.state}/>
                </AuthUserContext.Provider>
            );
        }
    }

export default withAuthentication;
