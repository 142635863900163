import React from 'react';
import ReactDOM from 'react-dom';
import Loadable from 'react-loadable';
import App from './App';
import { Frontload } from 'react-frontload';
import { BrowserRouter } from 'react-router-dom';
import StateManager from './StateManager';
import * as serviceWorker from './serviceWorker';

const Application = (
    <BrowserRouter>
        <Frontload noServerRender={true}>
            <StateManager.Client
                initialState={(window as any).__PRELOADED_STATE__}
                renderApp={(stateManager, initialState) => (
                    <App stateManager={stateManager}
                         initialState={initialState}/>
                )}
            />
        </Frontload>
    </BrowserRouter>
);

const root = document.querySelector('#root');

if (root) {
    if (root.hasChildNodes()) {
        Loadable.preloadReady().then(() => {
            ReactDOM.hydrate(Application, root);
        });
    } else {
        ReactDOM.render(Application, root);
    }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();