export default {
    ACCEPT_CODE: '/accept/:code',
    ACCOUNT: '/account',
    ACCOUNT_DELETE: '/account/delete',
    CHANGE_PASSWORD: '/account/password',
    RESET_PASSWORD: '/account/resetpassword',
    CREATE_PASSWORD: '/account/createpassword',
    HOME: '/',
    CONTACT: '/contact',
    INVITATIONS: '/invitations',
    INVOICES: '/invoices',
    LOGIN: '/login',
    LOGOUT: '/logout',
    MEMBERS: '/members/:role',
    MEMBERS_EDIT: '/members/edit/:id',
    NOTIFICATIONS: '/notifications/',
    ORGANIZATIONS: '/organizations',
    ORGANIZATION: '/organization',
    ONBOARDING: '/organization/welcome',
    ORGANIZATION_CREATE: '/organization/create',
    ORGANIZATION_EDIT: '/organizations/edit/:id',
    ORGANIZATION_DELETE: '/organizations/delete',
    PAYMENT_PLAN: '/payment/',
    PAYMENT_PURCHASE: '/payment/purchase/:quantity',
    PROFILE: '/account/profile',
    REPORTS: '/reports',
    REPORTS_TEAMS: '/reports/teams',
    REPORTS_USERS: '/reports/users/:userId',
    REPORTS_ME: '/reports/users/',
    REPORTS_ADD: '/reports/add',
    REPORTS_DATE: '/reports/:startDate(\d+)-:endDate(\d+)',
    SIGNUP: '/signup',
    TEAMS: '/teams',
    UNAUTHORIZED: '/unauthorized',
    USER_AGREEMENT: '/agreements/privacy',
    PUB_AGREEMENT: '/agreements/pub',

    ADMIN_MENU: '/admin',
    ADMIN_ORGANIZATIONS: '/admin/organizations',
    ADMIN_ORGANIZATIONS_EDIT: '/admin/organizations/:id',
    ADMIN_USERS: '/admin/users',
    ADMIN_USERS_EDIT: '/admin/users/:id',
    ADMIN_INVOICES_ADD: '/admin/invoices/add/:id',
};

export function createRouteLink(path: string, params?: any): string {
    let s = path;

    if (params) {
        Object.keys(params!).forEach(key => {
            s = s.replace(`:${key}`, params![key]);
        });
    }

    return s;
}