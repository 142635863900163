import { JsonConvert, JsonObject, JsonProperty, ValueCheckingMode } from 'json2typescript';
import { Ownable } from './Ownable';
import { Assignable, JsonSerializable } from './Serializable';
import DateConverter from './DateConverter';
import OrganizationRole from './OrganizationRole';

@JsonObject
export default class User extends Assignable<User> implements JsonSerializable, Ownable {

    @JsonProperty('id', Number, true)
    id?: number = undefined;

    @JsonProperty('source', String, true)
    source?: string = undefined;

    @JsonProperty('email', String, true)
    email?: string = undefined;

    @JsonProperty('fullName', String, true)
    fullName?: string = undefined;

    @JsonProperty('active', Boolean, true)
    active?: boolean = true;

    @JsonProperty('created', DateConverter, true)
    created?: Date = undefined;

    @JsonProperty('lastLogin', DateConverter, true)
    lastLogin?: Date = undefined;

    @JsonProperty('emailVerified', Boolean, true)
    emailVerified?: boolean = false;

    @JsonProperty('roles', [String], true)
    roles?: string[] = [];

    @JsonProperty('organizationRoles', [OrganizationRole], true)
    organizationRoles?: OrganizationRole[] = undefined;

    static fromJson(data: any): User {
        const jsonConvert = new JsonConvert();
        jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;

        return jsonConvert.deserialize(data, User) as User;
    }

    toJson(): any {
        const jsonConvert = new JsonConvert();
        jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
        return jsonConvert.serialize(this);
    }

    isOwner(user: User): boolean {
        return this.id === user.id;
    }
}
