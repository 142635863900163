import * as React from 'react';
import { LayoutProps } from './App';
import { Route } from 'react-router';
import './styles/AdminLayout.scss';
import './styles/forms.scss';
import Navigation from './Navigation';
import { Link } from 'react-router-dom';
import Routes from './Routes';
import OrganizationHeader from './OrganizationHeader';
import DefaultMeta from './DefaultMeta';

const AdminLayout = ({component: Component, ...rest}: LayoutProps<any>) => (
    <Route {...rest} render={matchProps => (
        <div className="admin-layout">
            <DefaultMeta/>
            <header>
                <h1 className={'ms-font-su'}>
                    <Link to={Routes.HOME}>
                        Tempen
                    </Link>
                </h1>
                <OrganizationHeader title={'Tempen'}/>
            </header>
            <Navigation/>
            <Component {...matchProps} />
        </div>
    )}/>
);

export default AdminLayout;
