import { JsonConvert, JsonObject, JsonProperty, ValueCheckingMode } from 'json2typescript';
import { Ownable } from './Ownable';
import { Assignable, JsonSerializable } from './Serializable';
import User from './User';

export type OrganizationRoles = 'owner' | 'reporting' | 'user';

export const OrganizationOwnerRole: OrganizationRoles = 'owner';
export const OrganizationReportingRole: OrganizationRoles = 'reporting';
export const OrganizationUserRole: OrganizationRoles = 'user';

export const organizationRoleNames = {
    owner: 'Administratör',
    reporting: 'Rapportgranskare',
    user: 'Användare'
};

@JsonObject
export default class OrganizationRole extends Assignable<OrganizationRole> implements JsonSerializable, Ownable {

    @JsonProperty('id', Number, true)
    id?: number = undefined;

    @JsonProperty('userId', Number)
    userId?: number = undefined;

    @JsonProperty('organizationId', Number)
    organizationId?: number = undefined;

    @JsonProperty('teamId', Number, true)
    teamId?: number | null = null;

    @JsonProperty('role', String)
    role?: OrganizationRoles = undefined;

    static fromJson(data: any): OrganizationRole {
        const jsonConvert = new JsonConvert();
        jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;

        return jsonConvert.deserializeObject(data, OrganizationRole) as OrganizationRole;
    }

    toJson(): any {
        const jsonConvert = new JsonConvert();
        jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
        return jsonConvert.serializeObject(this);
    }

    isOwner(user: User): boolean {
        return user && this.userId === user.id;
    }
}
