class EventEmitter {
    listeners: Map<string, [Function]>;

    constructor() {
        this.listeners = new Map();
    }

    addListener(label: string, callback: Function) {
        if (this.listeners.has(label)) {
            this.listeners.get(label)!.push(callback);
        } else {
            this.listeners.set(label, [callback]);
        }
    }

    removeListener(label: string, callback: Function) {
        let listeners = this.listeners.get(label),
            index;

        if (listeners && listeners.length) {
            index = listeners.reduce(
                (i, listener, idx) => {
                    return ((typeof listener === 'function') && listener === callback) ? i = idx : i;
                },
                -1);

            if (index > -1) {
                listeners.splice(index, 1);
                this.listeners.set(label, listeners);
                return true;
            }
        }
        return false;
    }

    emit(label: string, ...args: Array<any>) {
        let listeners = this.listeners.get(label);

        if (listeners && listeners.length) {
            listeners.forEach((listener) => {
                listener(...args);
            });
            return true;
        }
        return false;
    }
}

export default new EventEmitter();