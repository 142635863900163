import { JsonConvert, JsonObject, JsonProperty, ValueCheckingMode } from 'json2typescript';
import { Ownable } from './Ownable';
import { Assignable, JsonSerializable } from './Serializable';
import User from './User';

export type UserRoles = 'administrator';

export const AdministratorRole: UserRoles = 'administrator';

@JsonObject
export default class UserRole extends Assignable<UserRole> implements JsonSerializable, Ownable {

    @JsonProperty('id', Number, true)
    id?: number = undefined;

    @JsonProperty('userId', Number)
    userId?: number = undefined;

    @JsonProperty('role', String)
    role?: UserRoles = undefined;

    static fromJson(data: any): UserRole {
        const jsonConvert = new JsonConvert();
        jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;

        return jsonConvert.deserialize(data, UserRole) as UserRole;
    }

    toJson(): any {
        const jsonConvert = new JsonConvert();
        jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
        return jsonConvert.serialize(this);
    }

    isOwner(user: User): boolean {
        return user && this.userId === user.id;
    }
}

export function hasRole(user: User, userRoles: UserRole[], role: UserRoles): boolean {
    return hasAnyRole(user, userRoles, [role]);
}

export function hasAnyRole(user: User, userRoles: UserRole[], roles: UserRoles[]): boolean {
    if (user && userRoles) {
        return userRoles.filter(x => x.userId === user.id &&
            x.role &&
            roles.indexOf(x.role) !== -1).length > 0;
    }

    return false;
}
