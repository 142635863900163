import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import { initializeIcons } from 'office-ui-fabric-react';
import Loading from './Loading';
import Routes from './Routes';
import Home from './Home';
import DefaultLayout from './DefaultLayout';
import AdminLayout from './AdminLayout';
import './styles/App.scss';
import './styles/forms.scss';
import Contact from './Contact';
import { IStateManager } from './StateManager';

initializeIcons();

const LoadableLogin = Loadable({
    loader: () => import('./account/LoginView'),
    loading: () => <Loading/>
});

const LoadableForgotPassword = Loadable({
    loader: () => import('./account/ResetPassword'),
    loading: () => <Loading/>
});

const LoadableCreatePassword = Loadable({
    loader: () => import('./account/CreatePasswordView'),
    loading: () => <Loading/>
});

const LoadableSignup = Loadable({
    loader: () => import('./account/SignupView'),
    loading: () => <Loading/>
});

const LoadableOrganizations = Loadable({
    loader: () => import('./organizations/Organizations'),
    loading: () => <Loading/>
});

const LoadableCreateOrganization = Loadable({
    loader: () => import('./organizations/CreateOrganization'),
    loading: () => <Loading/>
});

const LoadableOrganizationMenu = Loadable({
    loader: () => import('./organizations/OrganizationMenu'),
    loading: () => <Loading/>
});

const LoadableMembers = Loadable({
    loader: () => import('./organizations/Members'),
    loading: () => <Loading/>
});

const LoadableMemberEdit = Loadable({
    loader: () => import('./organizations/MemberEdit'),
    loading: () => <Loading/>
});

const LoadableInvitations = Loadable({
    loader: () => import('./organizations/Invitations'),
    loading: () => <Loading/>
});

const LoadableAccceptInvitation = Loadable({
    loader: () => import('./account/AcceptInvitation'),
    loading: () => <Loading/>
});

const LoadableTeams = Loadable({
    loader: () => import('./teams/Teams'),
    loading: () => <Loading/>
});

const LoadableAddReport = Loadable({
    loader: () => import('./reports/AddReport'),
    loading: () => <Loading/>
});

const LoadableReportsMenu = Loadable({
    loader: () => import('./reports/ReportMenu'),
    loading: () => <Loading/>
});

const LoadableReportForTeams = Loadable({
    loader: () => import('./reports/ReportForTeams'),
    loading: () => <Loading/>
});

const LoadableReportForUsers = Loadable({
    loader: () => import('./reports/ReportForUsers'),
    loading: () => <Loading/>
});

const LoadableProfile = Loadable({
    loader: () => import('./account/ProfileView'),
    loading: () => <Loading/>
});

const LoadableAccountMenu = Loadable({
    loader: () => import('./account/AccountMenu'),
    loading: () => <Loading/>
});

const LoadableChangePassword = Loadable({
    loader: () => import('./account/ChangePasswordView'),
    loading: () => <Loading/>
});

const LoadableDeleteAccount = Loadable({
    loader: () => import('./account/DeleteAccount'),
    loading: () => <Loading/>
});

const LoadableDeleteOrganization = Loadable({
    loader: () => import('./organizations/DeleteOrganization'),
    loading: () => <Loading/>
});

const LoadablePlan = Loadable({
    loader: () => import('./payment/PlanView'),
    loading: () => <Loading/>
});

const LoadablePurchase = Loadable({
    loader: () => import('./payment/PurchaseView'),
    loading: () => <Loading/>
});

const LoadableInvoices = Loadable({
    loader: () => import('./organizations/Invoices'),
    loading: () => <Loading/>
});

const LoadableNotifications = Loadable({
    loader: () => import('./notifications/Notifications'),
    loading: () => <Loading/>
});

const LoadableUserAgreement = Loadable({
    loader: () => import('./UserAgreement'),
    loading: () => <Loading/>
});

const LoadablePubAgreement = Loadable({
    loader: () => import('./agreements/PubAgreement'),
    loading: () => <Loading/>
});

const LoadableAdminMenu = Loadable({
    loader: () => import('./admin/AdminMenu'),
    loading: () => <Loading/>
});

const LoadableAdminOrganizations = Loadable({
    loader: () => import('./admin/Organizations'),
    loading: () => <Loading/>
});

const LoadableAdminOrganizationEdit = Loadable({
    loader: () => import('./admin/EditOrganization'),
    loading: () => <Loading/>
});

const LoadableAdminInvoicesAdd = Loadable({
    loader: () => import('./admin/AddInvoice'),
    loading: () => <Loading/>
});

const LoadableOrganizationOnboarding = Loadable({
    loader: () => import('./organizations/Onboarding'),
    loading: () => <Loading/>
});

export type LayoutProps<T> = T & { component: any, stateManager: IStateManager };

interface AppProps {
    stateManager?: IStateManager;
    initialState?: any;
}

export default class App extends React.Component<AppProps> {
    render() {
        const {stateManager} = this.props;

        return (
            <div className={'ms-fontSize-l'}>
                <Switch>
                    <Route path={Routes.HOME} exact render={(matchedProps) => <Home {...matchedProps}
                                                                                    {...stateManager!}/>}/>
                    <Route path={Routes.CONTACT} exact render={(matchedProps) => <Contact {...matchedProps}
                                                                                          {...stateManager!}/>}/>
                    <DefaultLayout path={Routes.LOGIN} component={LoadableLogin} stateManager={stateManager}/>
                    <DefaultLayout path={Routes.UNAUTHORIZED} exact component={() => <div>Tillträde nekas</div>}/>
                    <DefaultLayout path={Routes.SIGNUP} exact component={LoadableSignup}/>
                    <DefaultLayout path={Routes.ACCEPT_CODE} exact component={LoadableAccceptInvitation}/>
                    <DefaultLayout path={Routes.USER_AGREEMENT} exact component={LoadableUserAgreement}/>

                    <AdminLayout path={Routes.ACCOUNT} exact component={LoadableAccountMenu}/>
                    <AdminLayout path={Routes.PROFILE} exact component={LoadableProfile}/>
                    <AdminLayout path={Routes.ACCOUNT_DELETE} exact component={LoadableDeleteAccount}/>
                    <AdminLayout path={Routes.CHANGE_PASSWORD} exact component={LoadableChangePassword}/>
                    <AdminLayout path={Routes.RESET_PASSWORD} exact component={LoadableForgotPassword}/>
                    <AdminLayout path={Routes.CREATE_PASSWORD} exact component={LoadableCreatePassword}/>

                    <AdminLayout path={Routes.ORGANIZATIONS} exact component={LoadableOrganizations}/>
                    <AdminLayout path={Routes.ORGANIZATION} exact component={LoadableOrganizationMenu}/>
                    <AdminLayout path={Routes.ONBOARDING} exact component={LoadableOrganizationOnboarding}/>
                    <AdminLayout path={Routes.ORGANIZATION_CREATE} exact component={LoadableCreateOrganization}/>
                    <AdminLayout path={Routes.ORGANIZATION_EDIT} exact component={LoadableCreateOrganization}/>
                    <AdminLayout path={Routes.ORGANIZATION_DELETE} exact component={LoadableDeleteOrganization}/>

                    <AdminLayout path={Routes.INVOICES} exact component={LoadableInvoices}/>

                    <AdminLayout path={Routes.NOTIFICATIONS} exact component={LoadableNotifications}/>

                    <AdminLayout path={Routes.PAYMENT_PLAN} exact component={LoadablePlan}/>
                    <AdminLayout path={Routes.PAYMENT_PURCHASE} exact component={LoadablePurchase}/>

                    <AdminLayout path={Routes.MEMBERS_EDIT} component={LoadableMemberEdit}/>
                    <AdminLayout path={Routes.MEMBERS} component={LoadableMembers}/>

                    <AdminLayout path={Routes.INVITATIONS} exact component={LoadableInvitations}/>

                    <AdminLayout path={Routes.TEAMS} exact component={LoadableTeams}/>

                    <AdminLayout path={Routes.REPORTS} exact component={LoadableReportsMenu}/>
                    <AdminLayout path={Routes.REPORTS_TEAMS} exact component={LoadableReportForTeams}/>
                    <AdminLayout path={Routes.REPORTS_USERS} component={LoadableReportForUsers}/>
                    <AdminLayout path={Routes.REPORTS_ME} exact component={LoadableReportForUsers}/>
                    <AdminLayout path={Routes.REPORTS_DATE} component={LoadableReportForTeams}/>

                    <AdminLayout path={Routes.REPORTS_ADD} exact component={LoadableAddReport}/>

                    <AdminLayout path={Routes.PUB_AGREEMENT} exact component={LoadablePubAgreement}/>

                    <AdminLayout path={Routes.ADMIN_MENU} exact component={LoadableAdminMenu}/>
                    <AdminLayout path={Routes.ADMIN_ORGANIZATIONS} exact component={LoadableAdminOrganizations}/>
                    <AdminLayout path={Routes.ADMIN_ORGANIZATIONS_EDIT} component={LoadableAdminOrganizationEdit}/>

                    <AdminLayout path={Routes.ADMIN_INVOICES_ADD} component={LoadableAdminInvoicesAdd}/>

                    <DefaultLayout component={() => <div style={{padding: 40}}>Sidan finns inte</div>}/>
                </Switch>
            </div>
        );
    }
}

